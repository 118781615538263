import React, {memo, useEffect, useState} from 'react';
import Icon from '@common/components/common/Icon';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {REQUEST_FORM_DELAY} from '@common/constants';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import ym from 'react-yandex-metrika';

const SuccessTooltip = () => {
  const t = useTranslation('forms') as ITranslation['forms']
  const [isActive, setIsActive] = useState(false)
  const {requestForm} = useTypedSelector(state => state.app)

  useEffect(() => {
    if (requestForm?.status === 'success') {
      setTimeout(() => {
        setIsActive(true);
        ym('reachGoal', 'request_send');
      }, REQUEST_FORM_DELAY)
    }
  }, [requestForm?.status])

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(false);
      }, REQUEST_FORM_DELAY * 5)
    }
  }, [isActive])

  return (
    <div className={`success-tooltip ${isActive ? 'is-active' : ''}`} data-success-tooltip="">
      <div className="success-tooltip__wrapper">
        <button className="success-tooltip__close" aria-label="Кнопка закрытия tooltip"
                onClick={() => setIsActive(false)}>
          <Icon className="success-tooltip__close-icon" width={14} height={14} name="icon-close"/>
        </button>
        <div className="success-tooltip__status">
          <Icon className="success-tooltip__icon" width={19} height={13} name="icon-success"/>
        </div>
        <div className="success-tooltip__box">
          <h3 className="success-tooltip__title">{t.successTooltip.title}</h3>
          <p className="success-tooltip__text">{t.successTooltip.description}</p>
        </div>
      </div>
    </div>
  )
}

export default memo(SuccessTooltip)
