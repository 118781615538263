import {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from 'react';

export function useStateRef<T>(initialValue: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [value, setValue] = useState<T>(initialValue)
  const ref = useRef<T>(initialValue)
  useEffect(() => {
    ref.current = value
  }, [value])
  return [value, setValue, ref]
}
