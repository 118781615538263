import React, {FC, memo} from 'react';
import {Form, Input} from 'antd';
import {FORM_EMAIL_VALIDATE_RULE, FORM_PHONE_VALIDATE_RULE, FORM_REQUIRED_RULE} from '@common/constants';
import {IModalFormProps} from '@common/types';
import {NamePath} from 'rc-field-form/es/interface';
import {IMaskInput} from 'react-imask';
import {useTypedSelector} from '@common/hooks/useTypedSelector';

const HeaderTypeCustomFields: FC<IModalFormProps> = ({t, checkFieldLabel}) => {
  const {settings} = useTypedSelector(state => state.app)
  
  return (
    <>
      {settings?.formModal?.fields?.map((f, index) => {
        let rules = [FORM_REQUIRED_RULE]
        switch (f.type) {
          case 'email':
            rules.push(FORM_EMAIL_VALIDATE_RULE)
            break
          case 'phone':
            rules.push(FORM_PHONE_VALIDATE_RULE)
            break
        }
        return (
          <Form.Item key={`form_item_${f.key}_${index}`} noStyle={true} name={f.key as NamePath} rules={rules}>
            <div className="custom-input form-modal__input-box">
              <label>
                {f.type === 'phone' ? (
                  <IMaskInput
                    mask="+{7} (000) 000 - 00 - 00"
                    id={f.key}
                  />
                ) : (
                  <Input id={f.key} type={f.type}/>
                )}
                <span className={`custom-input__label ${checkFieldLabel(f.key) ? 'is-focus' : ''}`}>
                  {f.placeholder}
                </span>
              </label>
            </div>
          </Form.Item>
        )
      })}
    </>
  )
}

export default memo(HeaderTypeCustomFields)
