import React, {FC, memo} from 'react';
import {IModalFormProps} from '@common/types';
import Link from 'next/link';

const PrivacyField: FC<Omit<IModalFormProps, 'checkFieldLabel'> & {closeForm: () => void}> = ({ t, closeForm }) => {
  return (
    <p className="form-request__note">
      {t.policy.text}&nbsp;
      <Link className="form-request__note-link" href="/personal-information" onClick={closeForm}>
        {t.policy.textLink}
      </Link>
    </p>
  )
}

export default memo(PrivacyField)
