import React, {FC, memo} from 'react';
import {LoaderIcon} from '@components/common/LoaderIcon';
import Icon from '@components/common/Icon';
import {IModalFromSubmitButton} from '@common/types';
import {useTypedSelector} from '@common/hooks/useTypedSelector';

const SubmitButton: FC<IModalFromSubmitButton> = ({t, type}) => {
  const {modalForm, settings} = useTypedSelector(state => state.app)
  
  return (
    <button className={`btn btn--violet form-modal__button is-${modalForm?.status}`} type="submit">
      <span className="form-request__button-text">
        {type === 'header' && settings?.formModal?.submitButtonTitle ? (
          settings?.formModal?.submitButtonTitle
        ) : (
          type === 'download' ? t.download : t.button
        )}
      </span>
      <LoaderIcon/>
      <Icon
        width={19}
        height={13}
        name="icon-success"
        className="form-modal__button-icon form-modal__button-success"
      />
    </button>
  )
}

export default memo(SubmitButton)
