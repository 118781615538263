import React, {FC, memo} from 'react';
import {IMainProps} from '@common/types';
import ContactButtons from '@common/layout/components/Main/components/ContactButtons';
import VideoModalPreview from '@common/layout/components/Main/components/VideoModalPreview';
import RequestForm from '@common/layout/components/Main/components/RequestForm';
import SuccessTooltip from '@common/layout/components/Main/components/SuccessTooltip';
import ModalForm from '@common/layout/components/Main/components/ModalForm/ModalForm';


const Main: FC<IMainProps> = ({children}) => {
  return (
    <main>
      <ModalForm/>
      <ContactButtons/>
      {children}
      <VideoModalPreview/>
      <RequestForm/>
      <SuccessTooltip/>
    </main>
  )
}


export default memo(Main)
