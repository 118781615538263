import {createContext} from 'react';

export const AmoCrmPipelineContext = createContext<number | undefined>(undefined);

interface IAmoCrmPipelineProvider {
  children: JSX.Element[],
  pipelineId: string | undefined
}

export const AmoCrmPipelineProvider = ({children, pipelineId}: IAmoCrmPipelineProvider) => {
  const validPipelineId = pipelineId ? Number(pipelineId) : undefined;
  return <AmoCrmPipelineContext.Provider value={validPipelineId}>{children}</AmoCrmPipelineContext.Provider>
}
