import React, {memo, useEffect, useRef} from 'react';
import Icon from '@common/components/common/Icon';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useActions} from '@common/hooks/useActions';

const VideoModalPreview = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const actions = useActions()
  const {modalVideo} = useTypedSelector(state => state.app)
  const video = modalVideo?.video || null

  useEffect(() => {
    if (videoRef.current) {
      if (modalVideo?.open) {
        videoRef.current!!.play();
      } else {
        videoRef.current!!.pause();
        videoRef.current!!.currentTime = 0;
      }
    }
  }, [modalVideo?.open])

  const closeModal = () => {
    actions.setApp({
      modalVideo: {
        ...modalVideo,
        open: false
      }
    })
  }

  return (
    <div className={`modal modal--video-review ${modalVideo?.open ? 'is-active' : ''}`} data-modal="video-review">
      <div className="modal__wrapper">
        <div className="modal__overlay" onClick={closeModal}/>
        <div className="modal__content">
          {!!video && (
            <div className="modal-video">
              <video
                ref={(v) => videoRef.current = v}
                width={380}
                height={700}
                controls={true}
                playsInline={true}
                src={video.url}
              />
            </div>
          )}
          <button
            className="modal__close-btn"
            type="button"
            aria-label="Закрыть попап"
            onClick={closeModal}
          >
            <Icon name="icon-close" width={30} height={30}/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(VideoModalPreview)
