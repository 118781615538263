import React from 'react';
import Icon from '@common/components/common/Icon';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {ModalFormContent} from '@common/layout/components/Main/components/ModalForm/ModalFormContent';
import {useActions} from '@common/hooks/useActions';


const ModalForm = () => {
  const actions = useActions()
  const {modalForm} = useTypedSelector(state => state.app)

  const closeForm = () => {
    actions.setApp({modalForm: {form: modalForm?.form || null, open: false}})
  }

  return (
    <div className={`modal modal--form ${modalForm?.open ? 'is-active' : ''}`} data-modal={modalForm}>
      <div className="modal__wrapper">
        <div className="modal__overlay" onClick={closeForm}/>
        <div className="modal__content">
          <section className="form-modal is-initialized">
            {modalForm?.form && (
              <ModalFormContent type={modalForm.form} closeForm={closeForm}/>
            )}
          </section>
          <button
            className="modal__close-btn"
            type="button"
            aria-label="Закрыть попап"
            onClick={closeForm}
          >
            <Icon width={30} height={30} name="icon-close"/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalForm
