import {Form} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {NamePath} from 'rc-field-form/es/interface';
import {useActions} from '@common/hooks/useActions';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {TRequestFormType} from '@common/types';
import {REQUEST_FORM_SOURCE, REQUEST_FORM_SOURCE_PARTNERS} from '@common/constants';
import ym from 'react-yandex-metrika';
import {useRouter} from 'next/router';
import {AmoCrmPipelineContext} from '@common/context/AmoCrm';

export const useRequestForm = (type: TRequestFormType) => {
  const pipelineId = useContext(AmoCrmPipelineContext)
  const router = useRouter()
  const actions = useActions()
  const {modalForm, requestForm, blockForm, settings} = useTypedSelector(state => state.app)
  const [form] = Form.useForm()
  const [focusedInput, setFocusedInput] = useState<string>('')

  useEffect(() => {
    if (requestForm?.open) {
      ym('reachGoal', 'request_open')
    }
  }, [requestForm?.open])

  useEffect(() => {
    const reset = () => {
      form.resetFields()
      setFocusedInput('')
    }

    switch (type) {
      case 'request':
        if (requestForm?.isClear) reset()
        break
      case 'requestPartners':
        if (requestForm?.isClear) reset()
        break
      case 'block':
        if (blockForm?.isClear) reset()
        break
      default:
        if (modalForm?.isClear) reset()
        break
    }
  }, [modalForm?.isClear, blockForm?.isClear, requestForm?.isClear])

  const checkFieldLabel = (field: string) => {
    return focusedInput === field || form?.getFieldValue(field as NamePath)
  }

  const sendForm = () => {
    let values = form.getFieldsValue()
    switch (type) {
      case 'request':
        const source = REQUEST_FORM_SOURCE.find(v => v.value === values?.source)?.name?.ru || null
        values = {
          ...values,
          description: `Заявка из интро\nЗа что отвечаете: ${values?.responsible}\n
          Опишите вашу задачу, кратко: ${values?.tasks}\nКак с вами связаться: ${values?.phone}\n
          Откуда вы о нас узнали: ${source ?? 'Не указано'}\nИсточник: https://mobileup.ru${router.asPath}`,
          pipeline_id: pipelineId,
        }
        break
      case 'requestPartners':
        const sourcePartners = REQUEST_FORM_SOURCE_PARTNERS.find(v => v.value === values?.source)?.name?.ru || null
        values = {
          file: values?.file,
          description: `Заявка из партнерства\n
          Имя: ${values?.nameContacts}\n
          Фамилия: ${values?.surnameContacts}\n
          Название компании: ${values?.nameCompanyContacts}\n
          Должность: ${values?.positionContacts}\n
          Email: ${values?.emailContacts}\n
          Telegram: ${values?.telegramContacts}\n
          Сайт: ${values?.siteContacts}\n
          Цель партнерства: ${sourcePartners}\n
          Компетенции, ставки: ${values?.competencies}\n
          Источник: https://mobileup.ru${router.asPath}`,
          pipeline_id: pipelineId,
        }
        break
      case 'header':
        let description: string | null = null
        let phone: string = ''
        if (!!settings?.formModal?.fields?.length) {
          description = settings?.formModal.fields.map(f => {
            if (f.type === 'phone' && !phone) {
              phone = values[f.key]
            }
            return `\n${f.placeholder}: ${values[f.key]}`
          }).join('')
        }
        values = {
          ...values,
          description: `Заявка из хедера${description ?? ''}`,
          phone: phone ? `+${phone.replace(/\D/g, '')}` : '+71111111111',
          pipeline_id: pipelineId,
        }
        break
      case 'download':
        values = {
          ...values,
          description: 'Скачивание презентации',
          phone: '+71111111111',
          pipeline_id: pipelineId,
        }
        break
      case 'block':
        values = {
          ...values,
          description: 'Заявка из кейсов',
          pipeline_id: pipelineId,
        }
        break
    }
    actions.sendRequestForm({
      data: values,
      type
    })
  }

  return {
    form,
    focusedInput,
    setFocusedInput,
    checkFieldLabel,
    sendForm
  }
}
