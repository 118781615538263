import React, {FC, memo} from 'react';
import {Form, Input} from 'antd';
import {FORM_EMAIL_VALIDATE_RULE, FORM_REQUIRED_RULE} from '@common/constants';
import {IModalFormProps} from '@common/types';

const HeaderTypeFields: FC<IModalFormProps> = ({t, checkFieldLabel}) => {
  return (
    <>
      <Form.Item noStyle={true} name="nameContact" rules={[FORM_REQUIRED_RULE]}>
        <div className="custom-input form-modal__input-box">
          <label>
            <Input id="nameContact"/>
            <span className={`custom-input__label ${checkFieldLabel('nameContact') ? 'is-focus' : ''}`}>
              {t.name}
            </span>
          </label>
        </div>
      </Form.Item>
      <Form.Item noStyle={true} name="email" rules={[FORM_REQUIRED_RULE, FORM_EMAIL_VALIDATE_RULE]}>
        <div className="custom-input form-modal__input-box">
          <label>
            <Input id="email"/>
            <span className={`custom-input__label ${checkFieldLabel('email') ? 'is-focus' : ''}`}>
              {t.email}
            </span>
          </label>
        </div>
      </Form.Item>
    </>
  )
}

export default memo(HeaderTypeFields)
