import React, {FC} from 'react';
import Head from 'next/head';
import {ILayoutProps} from '@common/types';
import Header from '@common/layout/components/Header';
import Main from '@common/layout/components/Main';
import Footer from '@common/layout/components/Footer';
import {useMobileSafari} from '@common/hooks/useMobileSafari';
import {useModal} from '@common/hooks/useModal';
import {useDraftMode} from '@common/hooks/useDraftMode';
import {getFromCDN} from '@common/functions';
import {AmoCrmPipelineProvider} from '@common/context/AmoCrm';


const Layout: FC<ILayoutProps> = ({
                                    title = 'MobileUp',
                                    description = 'MobileUp',
                                    pipelineId,
                                    classNames = [],
                                    children
                                  }) => {
  useMobileSafari()
  useModal()
  useDraftMode()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
        <meta name="description" content={description || 'MobileUp'}/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
        <link rel="icon" type="image/x-icon" href={getFromCDN('/favicon/favicon.ico')}/>
        <link rel="apple-touch-icon" sizes="180x180" href={getFromCDN('/favicon/apple-touch-icon.png')}/>
        <link rel="icon" type="image/png" sizes="32x32" href={getFromCDN('/favicon/favicon-32x32.png')}/>
        <link rel="icon" type="image/png" sizes="16x16" href={getFromCDN('/favicon/favicon-16x16.png')}/>
        <link rel="manifest" href={getFromCDN('/favicon/site.webmanifest')}/>
        <link rel="mask-icon" color="#5bbad5" href={getFromCDN('/favicon/safari-pinned-tab.svg')}/>
        <link rel="prefetch" href="/fonts/GraphikLCG-Regular.woff2" as="font" crossOrigin="anonymous"/>
        <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml"/>
      </Head>

      <div className={`wrapper ${classNames.join(' ')}`}>
        <AmoCrmPipelineProvider pipelineId={pipelineId}>
          <Header/>
          <Main>{children}</Main>
          <Footer/>
        </AmoCrmPipelineProvider>
      </div>
    </>
  )
}

export default Layout
